import React, { useEffect, useState } from "react";
import Header from "./common/Header";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./common/Footer/Footer";
import NavRoutes from "./NavRoute/NavRoute";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Amplify, { Auth } from "aws-amplify";
import IdleTimer from "react-idle-timer";

function App() {
  const [tokenFlag, setTokenFlag] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { state } = useSelector((state) => {
    return {
      state: state.LoginReducer.flag,
    };
  });
  const [idleTimer, setIdleTimer] = useState(null);
  const [showHeaderFooter, setShowHeaderFooter] = useState(true);
  async function TokenGen() {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const { refreshToken } = cognitoUser.getSignInUserSession();
      cognitoUser.refreshSession(refreshToken, (err, session) => {
        localStorage.setItem("accessToken", session.accessToken.jwtToken);
        localStorage.setItem("exp", session.accessToken.payload.exp);
        TokenRefresh();
      });
    } catch {}
  }
  const TokenRefresh = () => {
    const sessionInterval =
      new Date(localStorage.getItem("exp") * 1000) - new Date();
    Amplify.configure({
      Auth: {
        mandatorySignIn: true,
        region: process.env.REACT_APP_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
        authenticationFlowType: "USER_PASSWORD_AUTH",
      },
      oauth: {
        domain: process.env.REACT_APP_USER_POOL_DOMAIN_NAME,
        scope: [
          "email",
          "profile",
          "openid",
          "aws.cognito.signin.user.admin",
          "ga-launchpad-infra-aws-pgi/access_db_data",
        ],
        redirectSignIn: window.location.origin,
        redirectSignOut: window.location.origin,
        responseType: "code",
      },
    });
    setTimeout(() => {
      TokenGen();
    }, sessionInterval);
  };
  const onIdle = () => {
    history.push("/");
    localStorage.clear();
    sessionStorage.clear();
    Auth.signOut();
    alert(
      "As you were idle for 7 hours your session was timed out, please re-login"
    );
  };

  useEffect(() => {
    if (state || localStorage.getItem("login") !== null) {
      setTokenFlag(true);
      TokenRefresh();
    } else {
      localStorage.clear();
      sessionStorage.clear();
      Auth.signOut();
      setTokenFlag(false);
    }
  }, [state, localStorage.getItem("login")]);
  const onActive = () => {};

  useEffect(() => {
    if (location.pathname === "/quicksight") {
      setShowHeaderFooter(false);
    } else {
      setShowHeaderFooter(true);
    }
  }, []);

  return (
    <div>
      {tokenFlag && (
        <div>
          {showHeaderFooter ? <Header /> : null}

          <IdleTimer
            ref={(ref) => {
              setIdleTimer(ref);
            }}
            element={document}
            onActive={onActive}
            onIdle={onIdle}
            timeout={1000 * 60 * 410}
          />
        </div>
      )}
      <div className="route-container">
        <NavRoutes />
      </div>
      <ToastContainer autoClose={3000} hideProgressBar />
      {tokenFlag && showHeaderFooter ? <Footer /> : null}
    </div>
  );
}

export default App;
