import axios from "axios";
import React, { FC, useEffect, useState } from "react";
import { addDeleteReport, getAPIBaseURL } from "../../api/apiUtils";
import { Unauthorized } from "../errorPages/Unauthorized.tsx";

export const UpdateReportCategory: FC = () => {
  const [menuName, setMenuName] = useState();
  const [columnName, setColumnName] = useState();
  const [showCategory, setShowCategory] = useState(false);
  const [arrState, setArrState] = useState();
  const [deleteArray, setDeleteArray] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [priorityError, setPriorityError] = useState(false);
  const [successMsg, setSuccessMsg] = useState();
  const [failureMsg, setFailureMsg] = useState();
  let isUpdateAllowed = localStorage.getItem("hasUpdateAccess");
  isUpdateAllowed = JSON.parse(isUpdateAllowed);

  useEffect(() => {
    document.title = "Manage Report Category";
    const script = document.createElement("script");
    script.src =
      "https://www.principalcdn.com/css/principal-design-system/pds/latest/pds.min.js";
    script.async = true;
    document.body.appendChild(script);
    const inputScript = document.createElement("script");
    inputScript.src =
      "https://www.principalcdn.com/css/principal-design-system/helperSelect/v8/helperSelect.min.js";
    inputScript.async = true;
    document.body.appendChild(inputScript);
    const alertScript = document.createElement("script");
    alertScript.src =
      "https://www.principalcdn.com/css/principal-design-system/alert/v21/alert.min.js";
    alertScript.async = true;
    document.body.appendChild(alertScript);
    const formScript = document.createElement("script");
    formScript.src =
      "https://www.principalcdn.com/css/principal-design-system/form/v21/form.min.js";
    formScript.async = true;
    document.body.appendChild(formScript);
    getRecords();
  }, []);

  const getRecords = () => {
    let data = {
      action: "list",
      menuName: "",
    };
    return axios
      .post(getAPIBaseURL() + process.env.REACT_APP_REPORT_CATEGORY, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      })
      .then((res) => {
        let arr = [];
        Object.keys(res.data.data).forEach(function (key) {
          res.data.data[key].map((a) => {
            arr.push({
              columnName: a.columnName,
              menuName: key,
              priority: a.priority,
              editingMode: false,
            });
          });
        });
        setArrState(arr);
      })
      .catch((err) => {
        return err;
      });
  };

  const validatePriority = (a, e, index) => {
    let res = arrState.filter((record) => {
      return (
        record.menuName === a.menuName &&
        record.priority === Number(e.target.value)
      );
    });
    const newArr = arrState.slice();
    newArr[index].priority = e.target.value;
    setArrState(newArr);
    if (res.length > 0) {
      setPriorityError(true);
      setTimeout(
        function () {
          setPriorityError(false);
        }.bind(this),
        5000
      );
    }
  };

  const addCall = async () => {
    let data = {
      action: "add",
      menuName: menuName,
      columnName: columnName,
    };

    let res = await addDeleteReport(data);
    if (res.status === 200) {
      setSuccessMsg(res.data);
      setIsSuccess(true);
      setTimeout(
        function () {
          setIsSuccess(false);
        }.bind(this),
        5000
      );
      getRecords();
    } else {
      setFailureMsg(res.data);
      setIsFailure(true);
      setTimeout(
        function () {
          setIsFailure(false);
        }.bind(this),
        5000
      );
    }
  };
  const delCall = async () => {
    let data = {
      action: "delete",
      menuName: deleteArray.menuName,
      columnName: deleteArray.columnName,
    };
    let res = await addDeleteReport(data);
    if (res.status === 200) {
      setSuccessMsg(res.data);
      setIsSuccess(true);
      setTimeout(
        function () {
          setIsSuccess(false);
        }.bind(this),
        5000
      );
      getRecords();
    } else {
      setFailureMsg(res.data);
      setIsFailure(true);
      setTimeout(
        function () {
          setIsFailure(false);
        }.bind(this),
        5000
      );
    }
  };

  const delArrMaintain = (record) => {
    setDeleteArray(record);
  };

  return (
    <>
      {isUpdateAllowed ? (
        <>
          <div style={{ verticalAlign: "middle" }}>
            <i
              data-feather="chevron-left"
              aria-hidden="true"
              style={{ verticalAlign: "middle" }}
            ></i>
            <a
              href="/manageReport"
              className="pds-link-emphasis"
              style={{ verticalAlign: "middle" }}
            >
              back
            </a>
            <button
              className="pds-button pds-button-tertiary-border"
              style={{
                marginLeft: "30px",
                textAlign: "left",
                verticalAlign: "left",
              }}
              onClick={() => {
                setShowCategory(true);
              }}
            >
              Add Report Category
            </button>
            <button
              className="pds-button pds-button-tertiary-border"
              style={{
                marginLeft: "30px",
                textAlign: "left",
                verticalAlign: "left",
              }}
              onClick={() => {
                delCall();
              }}
            >
              Delete Report Category
            </button>
            <a
              className="pds-button pds-button-tertiary-border"
              style={{
                marginLeft: "30px",
                textAlign: "left",
                verticalAlign: "left",
              }}
              href="/updatePriority"
            >
              Update Priority
            </a>
          </div>
          {showCategory && (
            <div style={{ marginTop: "16px", marginLeft: "60px" }}>
              <input
                id="helperInput"
                aria-label=""
                type="text"
                aria-describedby="helper-text-id"
                placeholder="Enter Column Name"
                style={{ height: "50px", paddingLeft: "7px" }}
                onChange={(e) => {
                  setColumnName(e.target.value);
                }}
              />
              <select
                id="selectPlaceholder"
                aria-label="Select placeholder"
                name="select-input"
                style={{ height: "50px", marginLeft: "16px" }}
                onChange={(e) => {
                  setMenuName(e.target.value);
                }}
              >
                <option value="" hidden>
                  Select Report Menu
                </option>
                <option value={"On Premise Reports"}>OnPremise Report</option>
                <option value={"Feedback"}>Feedback</option>
                <option value={"Self-Service"}>Self-service</option>
                <option value={"Applications"}>Applications</option>
              </select>
              <button
                className="pds-button pds-button-tertiary-border"
                style={{
                  marginLeft: "30px",
                  textAlign: "left",
                  verticalAlign: "left",
                }}
                onClick={() => {
                  //Function call for api
                  addCall();
                  setShowCategory(false);
                }}
              >
                Save
              </button>
            </div>
          )}
          <div
            className="pds-dataTable"
            style={{ width: "800px", paddingLeft: "20px" }}
          >
            <div className="pds-dataTable-table-container">
              <table
                className="pds-table-container pds-table pds-table-bordered"
                id="pds-datatable-example"
                aria-live="polite"
              >
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Column Name</th>
                    <th scope="col">Menu Name</th>
                    <th scope="col">Priority</th>
                  </tr>
                </thead>
                <tbody>
                  {arrState &&
                    arrState.map((a, index) => {
                      return (
                        <tr>
                          <td style={{ paddingLeft: "16px", width: "0px" }}>
                            <div className="pds-checkbox">
                              <input
                                name="pdsRadioOptions"
                                type="radio"
                                id={a}
                                value={a}
                                onClick={(e) => {
                                  //Input clicked
                                  delArrMaintain(a);
                                }}
                              />
                            </div>
                          </td>

                          <td
                            className="pds-dataTable-table-item"
                            style={{ minWidth: "0px", width: "300px" }}
                          >
                            <div style={{ maxWidth: "100px" }}>
                              {a.columnName}
                            </div>
                          </td>
                          <td
                            className="pds-dataTable-table-item"
                            style={{ minWidth: "0px", width: "300px" }}
                          >
                            <div style={{ maxWidth: "100px" }}>
                              {a.menuName}
                            </div>
                          </td>
                          <td
                            className="pds-dataTable-table-item"
                            style={{ minWidth: "0px" }}
                          >
                            {a.editingMode === true ? (
                              <input
                                value={a.priority}
                                style={{ width: "25px" }}
                                onChange={(e) => {
                                  validatePriority(a, e, index);
                                }}
                              />
                            ) : (
                              <div>{a.priority}</div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          {isSuccess && (
            <div
              className="pds-form"
              style={{
                paddingLeft: "40px",
                paddingRight: "200px",
              }}
            >
              <div
                className="pds-alert pds-alert-success"
                style={{
                  position: "fixed",
                  left: "0",
                  bottom: "0",
                  right: "0",
                  marginBottom: "80px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              >
                <div className="pds-card-section">
                  <div className="pds-alert-content" role="alert">
                    <span className="sr-only">Success. </span>
                    {successMsg}
                  </div>
                </div>
              </div>
            </div>
          )}
          {isFailure && (
            <div
              className="pds-form"
              style={{ paddingLeft: "40px", paddingRight: "200px" }}
            >
              <div
                className="pds-alert pds-alert-error"
                style={{
                  position: "fixed",
                  left: "0",
                  bottom: "0",
                  right: "0",
                  marginBottom: "80px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              >
                <div className="pds-card-section">
                  <div className="pds-alert-content" role="alert">
                    <span className="sr-only">Success. </span>
                    {failureMsg}
                  </div>
                </div>
              </div>
            </div>
          )}

          {priorityError && (
            <div
              className="pds-form"
              style={{ paddingLeft: "40px", paddingRight: "200px" }}
            >
              <div
                className="pds-alert pds-alert-error"
                style={{
                  position: "fixed",
                  left: "0",
                  bottom: "0",
                  right: "0",
                  marginBottom: "80px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              >
                <div className="pds-card-section">
                  <div className="pds-alert-content" role="alert">
                    <span className="sr-only">Success. </span>
                    Same Priority Exist for Menu column combination
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <Unauthorized />
      )}
    </>
  );
};
