import React, { FC, useEffect, useState } from "react";
import excelLogo from "../../assets/images/excel_wiki.svg";
import powerBILogo from "../../assets/images/powerbi_wiki.svg";
import powerPointLogo from "../../assets/images/powerpoint_wiki.svg";
import axios from "axios";
import { getAPIBaseURL } from "../../api/apiUtils";

export const DataTableReport: FC = (props) => {
  const excelIcon = (
    <img src={excelLogo} title="Excel" height={15} width={15} alt="Excel" />
  );
  const powerBIIcon = (
    <img src={powerBILogo} title="Excel" height={15} width={15} alt="powerBI" />
  );

  const fileIcon = (
    <i data-feather="file" aria-hidden="true" height={15} width={15}></i>
  );

  const powerPointIcon = (
    <img
      src={powerPointLogo}
      title="Excel"
      height={15}
      width={15}
      alt="PowerPoint"
    />
  );
  const [headers, setHeaders] = useState([]);
  const [htmlData, setHtmlData] = useState([[{ reportName: "" }]]);
  let isUpdateAllowed = localStorage.getItem("hasUpdateAccess");
  isUpdateAllowed = JSON.parse(isUpdateAllowed);

  useEffect(() => {
    let temp = [];
    document.title = props.type;

    axios
      .get(getAPIBaseURL() + process.env.REACT_APP_ONPREM_LIST + props.type, {
        headers: {
          "Content-Type": "text/csv",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      })
      .then((res) => {
        setHeaders(res.data.headers);
        for (let i = 0; i < res.data.maxRow; i++) {
          let td = [];
          res.data.headers.forEach((header) => {
            if (res.data.data[header].length > i) {
              td.push(res.data.data[header][i]);
            } else {
              td.push("");
            }
          });
          temp.push(td);
        }
        setHtmlData(temp);

        setTimeout(() => {
          const script = document.createElement("script");
          script.src =
              "https://www.principalcdn.com/css/principal-design-system/pds/latest/pds.min.js";
          script.async = true;
          document.body.appendChild(script);
        }, 500);
      })
      .catch((e) => {
        return e;
      });

    localStorage.setItem("reportMenu", props.type);

  }, []);

  return (
    <div>
      {isUpdateAllowed ? (
        <div>
          <a
            href="/manageReport"
            className="pds-button pds-button-tertiary-border"
            style={{ marginLeft: "10px" }}
          >
            Manage
          </a>
          <a
            href="/addReport"
            className="pds-button pds-button-tertiary-border"
            style={{ marginLeft: "20px" }}
          >
            Add Report
          </a>
        </div>
      ) : null
      }
      <div
        className="pds-dataTable"
        style={{
          overflowY: "hidden",
          paddingTop: "-30px",
          paddingRight: "20px",
          marginLeft: "10px",
          marginRight: "10px",
        }}
      >
        <div className="pds-dataTable-header">
          <p
            aria-label="Title of the dataTable"
            className="pds-dataTable-title"
          >
            {props.type === "On Premise Reports"
              ? "On Premises Reports"
              : props.type}
          </p>
          <div className="pds-searchInput pds-dataTable-smart-search">
            <input
              aria-label="Filter table data search bar"
              className="search inputSearch pds-input-small"
              type="search"
              placeholder="What are you looking for?"
            />
            <button
              type="submit"
              aria-label="Search"
              aria-disabled="true"
            ></button>
          </div>
        </div>
        <div className="pds-dataTable-table-container">
          <table
            className="pds-table-container pds-table pds-table-bordered"
            id="pds-datatable-example"
            aria-live="polite"
            style={{
              overflowY: "hidden",
              textAlign: "center",
              display: "inline-block",
            }}
          >
            <thead style={{ textAlign: "center" }}>
              <tr>
                {headers.map((a) => {
                  return (
                    <th
                      className="sort pds-dataTable-table-header"
                      scope="col"
                      style={{ minWidth: "0px" }}
                    >
                      {a}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody
              style={{
                fontSize: "10px",
              }}
            >
              {htmlData.map((rowE) => {
                return (
                  <tr>
                    {rowE.map((cellData) => {
                      return (
                        <td
                          // className="pds-dataTable-table-item"
                          style={{ paddingLeft: "10px" }}
                        >
                          <a href={cellData.reportURL} target="_blank">
                            {cellData.reportType === "e"
                              ? excelIcon
                              : cellData.reportType === "pb"
                              ? powerBIIcon
                              : cellData.reportType === "pp"
                              ? powerPointIcon
                              : cellData.reportType === "folder"
                              ? fileIcon
                              : ""}{" "}
                            {cellData.reportName}
                          </a>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
