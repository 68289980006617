import axios from "axios";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { getAPIBaseURL } from "../../api/apiUtils";

export const UpdatePriority: FC = () => {
  const [arrState, setArrState] = useState();
  const [originalArr, setOriginalArr] = useState();
  const [updateArr, setUpdateArr] = useState([]);
  const [priorityError, setPriorityError] = useState(false);
  const [dupliacteFlag, setDuplicateFlag] = useState(false);
  const [prioritySuccess, setPrioritySuccess] = useState(false);
  const [dupliactePriorityFlag, setDuplicatePriorityFlag] = useState(false);
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://www.principalcdn.com/css/principal-design-system/pds/latest/pds.min.js";
    script.async = true;
    document.body.appendChild(script);
    const alertScript = document.createElement("script");
    alertScript.src =
      "https://www.principalcdn.com/css/principal-design-system/alert/v21/alert.min.js";
    alertScript.async = true;
    document.body.appendChild(alertScript);
    getRecords();
  }, []);

  const updatePriorityArray = (record, e, index) => {
    let arr = [];
    let j = -1;
    const newArr = arrState.slice();
    newArr[index].priority = e.target.value;
    setArrState(newArr);

    setTimeout(() => {
      let res = arrState.filter((a) => {
        return (
          a.menuName === record.menuName &&
          a.priority === Number(e.target.value)
        );
      });
      if (res.length > 0) {
        setPriorityError(true);
        setDuplicateFlag(true);
        setTimeout(
          function () {
            setPriorityError(false);
          }.bind(this),
          5000
        );
      } else {
        setDuplicateFlag(false);
      }
    }, 700);

    if (updateArr.length === 0) {
      arr.push(record);
      setUpdateArr(arr);
    } else {
      updateArr.find((a, i) => {
        if (
          a.columnName === record.columnName &&
          a.menuName === record.menuName
        ) {
          j = i;
          return i;
        }
      });
      if (j !== -1) {
        // console.log("Index at", j);
        // console.log("Update array after splice", updateArr.splice(j, 1));
        updateArr.splice(j, 1);
      }
      updateArr.map((a) => {
        arr.push(a);
      });
      arr.push(record);
      setUpdateArr(arr);
    }
  };

  const filterData = (menu) => {
    if (menu !== "") {
      setArrState(
        originalArr.filter((record) => {
          return record.menuName === menu;
        })
      );
    } else {
      setArrState(originalArr);
    }
  };

  const updatePriorityAPI = () => {
    //TODO add validation before api call

    if (dupliacteFlag) {
      setDuplicatePriorityFlag(true);
      return;
    } else {
      setDuplicatePriorityFlag(false);
    }

    let data = {
      action: "update",
      update_priority: updateArr,
    };
    return axios
      .post(getAPIBaseURL() + process.env.REACT_APP_REPORT_CATEGORY, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      })
      .then((res) => {
        setPrioritySuccess(true);
        setTimeout(() => {
          setPrioritySuccess(false);
        }, 3000);
        getRecords();
      })
      .catch((e) => {});
  };

  const getRecords = () => {
    let data = {
      action: "list",
      menuName: "",
    };
    return axios
      .post(getAPIBaseURL() + process.env.REACT_APP_REPORT_CATEGORY, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      })
      .then((res) => {
        let arr = [];
        Object.keys(res.data.data).forEach(function (key) {
          res.data.data[key].map((a) => {
            arr.push({
              columnName: a.columnName,
              menuName: key,
              priority: a.priority,
            });
          });
        });
        setArrState(arr);
        setOriginalArr(arr);
      })
      .catch((err) => {
        return err;
      });
  };

  return (
    <>
      <div style={{ verticalAlign: "middle" }}>
        <i
          data-feather="chevron-left"
          aria-hidden="true"
          style={{ verticalAlign: "middle" }}
        ></i>
        <a
          href="/manageCategory"
          className="pds-link-emphasis"
          style={{ verticalAlign: "middle" }}
        >
          back
        </a>
      </div>
      <div style={{ paddingLeft: "20px", marginTop: "20px" }}>
        <select
          id="selectBasic"
          aria-label="Select basic"
          name="select-input"
          className="pds-select-small"
          style={{ width: "400px", height: "40px" }}
          onChange={(e) => {
            filterData(e.target.value);
          }}
        >
          <option value="">Filter by menu</option>
          <option value="On Premise Reports">On Premise Reports</option>
          <option value="Applications">Application</option>
          <option value="Self-Service">Self-Service</option>
          <option value="Feedback">Feedback</option>
        </select>
        <button
          className="pds-button pds-button-tertiary-border"
          style={{
            marginLeft: "425px",
            textAlign: "left",
            verticalAlign: "left",
          }}
          onClick={() => {
            //Do api call here
            updatePriorityAPI();
          }}
        >
          Update Priority
        </button>
      </div>

      <div
        className="pds-dataTable"
        style={{ width: "1000px", paddingLeft: "20px" }}
      >
        <div className="pds-dataTable-table-container">
          <table
            className="pds-table-container pds-table pds-table-bordered"
            id="pds-datatable-example"
            aria-live="polite"
            style={{ overflowY: "hidden", textAlign: "center" }}
          >
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>Column Name</th>
                <th style={{ textAlign: "center" }}>Menu Name</th>
                <th style={{ textAlign: "center" }}>Priority</th>
              </tr>
            </thead>
            <tbody>
              {arrState &&
                arrState.map((a, index) => {
                  return (
                    <tr>
                      <td
                        className="pds-dataTable-table-item"
                        style={{ minWidth: "0px", width: "300px" }}
                      >
                        <div style={{ maxWidth: "100px;" }}>{a.columnName}</div>
                      </td>
                      <td
                        className="pds-dataTable-table-item"
                        style={{ minWidth: "0px", width: "300px" }}
                      >
                        <div style={{ maxWidth: "100px;" }}>{a.menuName}</div>
                      </td>
                      <td
                        className="pds-dataTable-table-item"
                        style={{
                          width: "20px",
                        }}
                      >
                        <div>
                          <div className="pds-labeledInput">
                            <input
                              id="helperInput"
                              aria-label=""
                              type="number"
                              value={a.priority}
                              aria-describedby="helper-text-id"
                              style={{ height: "50px", paddingLeft: "7px" }}
                              onChange={(e) => {
                                updatePriorityArray(a, e, index);
                              }}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      {priorityError && (
        <div
          className="pds-form"
          style={{ paddingLeft: "40px", paddingRight: "200px" }}
        >
          <div
            className="pds-alert pds-alert-error"
            style={{
              position: "fixed",
              left: "0",
              bottom: "0",
              right: "0",
              marginBottom: "80px",
              marginLeft: "20px",
              marginRight: "20px",
            }}
          >
            <div className="pds-card-section">
              <div className="pds-alert-content" role="alert">
                <span className="sr-only">Success. </span>
                Same Priority Exist for Menu column combination
              </div>
            </div>
          </div>
        </div>
      )}
      {dupliactePriorityFlag && (
        <div
          className="pds-form"
          style={{ paddingLeft: "40px", paddingRight: "200px" }}
        >
          <div
            className="pds-alert pds-alert-error"
            style={{
              position: "fixed",
              left: "0",
              bottom: "0",
              right: "0",
              marginBottom: "80px",
              marginLeft: "20px",
              marginRight: "20px",
            }}
          >
            <div className="pds-card-section">
              <div className="pds-alert-content" role="alert">
                <span className="sr-only">Success. </span>
                Duplicate priority exist, please update
              </div>
            </div>
          </div>
        </div>
      )}
      {prioritySuccess && (
        <div
          className="pds-form"
          style={{ paddingLeft: "40px", paddingRight: "200px" }}
        >
          <div
            className="pds-alert pds-alert-success"
            style={{
              position: "fixed",
              left: "0",
              bottom: "0",
              right: "0",
              marginBottom: "80px",
              marginLeft: "20px",
              marginRight: "20px",
            }}
          >
            <div className="pds-card-section">
              <div className="pds-alert-content" role="alert">
                <span className="sr-only">Success. </span>
                Priority updated successfully
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
