import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {Auth} from "aws-amplify";
import { getAPIBaseURL } from "./../../api/apiUtils"

const QuicksightMenuList = (props) => {
  const [dashboardNames, setDashboardNames] = useState([]);
  const [dashboardIdsObj, setDashboardIdsObj] = useState({});
  let history = useHistory();
  let dropdownName = "Dev Dashboards";
  if (props.dropdownType === "Prod") dropdownName = "Prod Dashboards";

  const dropDownOnchange = (e) => {
    //history.push("/quicksight?dashboard_id=" + e.target.value);
    var newTab = window.open();
    newTab.opener = null;
    newTab.location = "/quicksight?dashboard_id=" + e.target.value+"&dashboard_type="+dashboardIdsObj[e.target.value]["dashboard_type"];
    localStorage.setItem(
      "selected_dashboard",
      e.target.options[e.target.selectedIndex].text
    );
  };

  useEffect(() => {
    async function fetchData() {
      axios
        .post(
          getAPIBaseURL() + process.env.REACT_APP_QUICKSIGHT_GET_DASHBOARD_LIST,
          { dashboard_select: props.dropdownType },
          {
            headers: {
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          setDashboardNames(res.data);

          let newDashboardIdsObj = {};
          res.data.map((dashboard) => {
            newDashboardIdsObj[dashboard.dashboard_id] = dashboard;
          })

          setDashboardIdsObj(newDashboardIdsObj);

        })
        .catch((error) => {
          console.log(error);
          localStorage.clear();
          sessionStorage.clear();
          Auth.signOut();
          history.push("/");
        });
    }
    fetchData();
  }, []);

  return (
    <select
      value="-1"
      onChange={dropDownOnchange}
      className="dropdown bg-primary"
      style={{ width: "150px", fontweight: "bold", border: "none" }}
    >
      <option key="-1" value="-1">
        {dropdownName}
      </option>
      {dashboardNames.map((name) => (
        <option key={name.dashboard_id} value={name.dashboard_id}>
          {name.dashboard_name}
        </option>
      ))}
    </select>
  );
};
export default QuicksightMenuList;
