import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './redux/store';
import Amplify from 'aws-amplify';
import './index.css'

Amplify.configure({
	Auth: {
		mandatorySignIn: true,
		region: process.env.REACT_APP_REGION,
		userPoolId: process.env.REACT_APP_USER_POOL_ID,
		userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
		authenticationFlowType: 'USER_PASSWORD_AUTH',
	},
	oauth: {
		domain: process.env.REACT_APP_USER_POOL_DOMAIN_NAME,
		scope: [ 'email', 'profile', 'openid','aws.cognito.signin.user.admin', 'ga-launchpad-infra-aws-pgi/access_db_data'],
		redirectSignIn: window.location.origin,
		redirectSignOut: window.location.origin,
		responseType: 'code',
	},
});

ReactDOM.render(
	<Router>
	<React.StrictMode>
		<Provider store={store}>
		<App />
		</Provider>
	</React.StrictMode>
	</Router>,
	document.getElementById('app')
);
