import axios from "axios";
import React, { FC, useEffect, useState } from "react";
import { addReport, getAPIBaseURL, getReportCategory } from "../../api/apiUtils";
import { Unauthorized } from "../errorPages/Unauthorized.tsx";

export const AddReport: FC = () => {
  const [reportName, setReportName] = useState();
  const [reportURL, setReportURL] = useState();
  const [reportType, setReportType] = useState("");
  const [reportMenu, setReportMenu] = useState("On Premise Reports");
  const [reportCategory, setReportCategory] = useState();
  const [errMsg, setErrMsg] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [successMsg, setSuccessMsg] = useState();
  const [failureMsg, setFailureMsg] = useState();
  let isUpdateAllowed = localStorage.getItem("hasUpdateAccess");
  isUpdateAllowed = JSON.parse(isUpdateAllowed);

  useEffect(() => {
    document.title = "Add Report";
    const formScript = document.createElement("script");
    formScript.src =
      "https://www.principalcdn.com/css/principal-design-system/form/v21/form.min.js";
    formScript.async = true;
    document.body.appendChild(formScript);
    const script = document.createElement("script");
    script.src =
      "https://www.principalcdn.com/css/principal-design-system/pds/latest/pds.min.js";
    script.async = true;
    document.body.appendChild(script);
    setReportMenu(localStorage.getItem("reportMenu"));
    fetchReportCategories(localStorage.getItem("reportMenu"));
  }, []);

  const [reportCategories, setReportCategories] = useState([]);

  const fetchReportCategories = async (menuName) => {
    let data = {
      action: "list",
      menuName: menuName,
    };

    return axios
      .post(getAPIBaseURL() + process.env.REACT_APP_REPORT_CATEGORY, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      })
      .then((res) => {
        let arrReport = [];
        let arr = res.data.data[menuName];
        arr.map((a) => {
          arrReport.push(a.columnName);
        });
        setReportCategories(arrReport);
        setReportCategory(arrReport[0]);
      })
      .catch((err) => {
        return err;
      });
  };

  const saveReport = async () => {
    if (
      reportName !== undefined &&
      reportURL !== undefined &&
      reportCategory !== undefined
    ) {
      let data = {
        reportName: reportName,
        reportURL: reportURL,
        reportType: reportType,
        columName: reportCategory,
        menuName: reportMenu,
      };
      let res = await addReport(data, "add");
      if (res.status === 200) {
        setSuccessMsg(res.data);
        setIsSuccess(true);
        setErrMsg(false);
        setTimeout(
          function () {
            setIsSuccess(false);
          }.bind(this),
          5000
        );

        setReportName("");
        setReportURL("");
        setReportName(undefined);
        setReportURL(undefined);
      } else {
        setFailureMsg(res.data);
        setIsFailure(true);
        setTimeout(
          function () {
            setIsFailure(false);
          }.bind(this),
          5000
        );
      }
    } else {
      setErrMsg(true);
    }
  };

  return (
    <>
      {isUpdateAllowed ? (
        <>
          <div style={{ verticalAlign: "middle" }}>
            <i
              data-feather="chevron-left"
              aria-hidden="true"
              style={{ verticalAlign: "middle" }}
            ></i>
            <a
              href="/onPremiseReport"
              className="pds-link-emphasis"
              style={{ verticalAlign: "middle" }}
            >
              back
            </a>
          </div>
          <div
            className="pds-form"
            style={{ paddingLeft: "40px", paddingRight: "200px" }}
          >
            <div className="pds-form-field">
              <div className="pds-labeledInput" style={{ marginTop: "40px" }}>
                <label htmlFor="pdsForm-labeledInputId" className="pds-label">
                  Report Name
                </label>

                <input
                  id="pdsForm-reportName"
                  aria-label=""
                  type="text"
                  value={reportName}
                  onChange={(e) => {
                    setErrMsg(false);
                    setReportName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="pds-form-field">
              <div className="pds-labeledInput" style={{ marginTop: "10px" }}>
                <label htmlFor="pdsForm-labeledInputId" className="pds-label">
                  Report URL
                </label>

                <input
                  id="pdsForm-reportURL"
                  aria-label=""
                  type="text"
                  value={reportURL}
                  onChange={(e) => {
                    setErrMsg(false);
                    setReportURL(e.target.value);
                  }}
                />
              </div>
            </div>
            <div>
              <div className="pds-form-field">
                <div className="pds-labeledInput" style={{ marginTop: "10px" }}>
                  <label htmlFor="pdsForm-labeledInputId" className="pds-label">
                    Select Report type
                  </label>
                </div>
                <div>
                  <select
                    id="selectBasic"
                    aria-label="Select basic"
                    name="select-input"
                    className="pds-select-small"
                    style={{ width: "400px", height: "40px" }}
                    onChange={(e) => {
                      setReportType(e.target.value);
                    }}
                  >
                    <option value="">Select Report Type</option>
                    <option value="e">Excel</option>
                    <option value="pb">PowerBi</option>
                    <option value="pp">PowerPoint</option>
                    <option value="folder">Folder</option>
                  </select>
                </div>
              </div>
            </div>

            <div>
              <div className="pds-form-field">
                <div className="pds-labeledInput" style={{ marginTop: "10px" }}>
                  <label htmlFor="pdsForm-labeledInputId" className="pds-label">
                    Select Report Menu
                  </label>
                </div>
                <div>
                  <select
                    id="selectBasic"
                    aria-label="Select basic"
                    name="select-input"
                    className="pds-select-small"
                    style={{ width: "400px", height: "40px" }}
                    onChange={(e) => {
                      fetchReportCategories(e.target.value);
                      setReportMenu(e.target.value);
                    }}
                  >
                    <option
                      value="On Premise Reports"
                      selected={reportMenu === "On Premise Reports"}
                    >
                      OnPremise
                    </option>
                    <option
                      value="Feedback"
                      selected={reportMenu === "Feedback"}
                    >
                      Feedback
                    </option>
                    <option
                      value="Self-Service"
                      selected={reportMenu === "Self-Service"}
                    >
                      Self-Service
                    </option>
                    <option
                      value="Applications"
                      selected={reportMenu === "Applications"}
                    >
                      Applications
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div>
              <div className="pds-form-field">
                <div className="pds-labeledInput" style={{ marginTop: "10px" }}>
                  <label htmlFor="pdsForm-labeledInputId" className="pds-label">
                    Select Report Category
                  </label>
                </div>
                <div>
                  <select
                    id="selectBasic"
                    aria-label="Select basic"
                    name="select-input"
                    className="pds-select-small"
                    style={{ width: "400px", height: "40px" }}
                    onChange={(e) => {
                      setReportCategory(e.target.value);
                    }}
                  >
                    <>
                      {reportCategories &&
                        reportCategories!.map((m) => {
                          return <option value={m}>{m}</option>;
                        })}
                    </>
                  </select>
                </div>
              </div>
            </div>
            {errMsg && (
              <div style={{ marginTop: "16px" }}>Please enter all details</div>
            )}
            <div className="pds-form-ctas" style={{ marginTop: "16px" }}>
              <button
                className="pds-button pds-button-primary"
                onClick={() => {
                  //Save api call
                  saveReport();
                }}
              >
                Save
              </button>
            </div>
            {isSuccess && (
              <div
                className="pds-alert pds-alert-success"
                style={{
                  position: "absolute",
                  left: "0",
                  bottom: "0",
                  right: "0",
                  marginBottom: "80px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              >
                <div className="pds-card-section">
                  <div className="pds-alert-content" role="alert">
                    <span className="sr-only">Success. </span>
                    {successMsg}
                  </div>
                </div>
              </div>
            )}
            {isFailure && (
              <div
                className="pds-alert pds-alert-error"
                style={{
                  position: "absolute",
                  left: "0",
                  bottom: "0",
                  right: "0",
                  marginBottom: "80px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              >
                <div className="pds-card-section">
                  <div className="pds-alert-content" role="alert">
                    <span className="sr-only">Success. </span>
                    {failureMsg}
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <Unauthorized />
      )}
    </>
  );
};
